import React from 'react';
import Social from '@components/Social';

import './index.scss';

const About = () => (
    <div className="about">
        <div className="about__social">
    
            <Social type="light" />
        </div>

    </div>
);

export default About;