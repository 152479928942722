import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import About from '@components/About'
import ReactMarkdown from 'react-markdown'
import BackgroundImage from 'gatsby-background-image'
import { DiscussionEmbed } from "disqus-react"
import SEO from "../components/seo"

import './post.scss'

const PostTemplate = ({ data }) => {
    const imageData = data.strapiPost.image.childImageSharp.fluid;

    const disqusConfig = {
        shortname: process.env.GATSBY_DISQUS_NAME,
        config: { identifier: data.strapiPost.slug },
      }

return (
    <Layout>
        <SEO 
            title={data.strapiPost.title}
            description={data.strapiPost.content.substring(0, 100)}
        />
        <div className="post-view">
            <BackgroundImage
                Tag="section"
                fluid={imageData}
                className="post-view__image"
            >
                <div className="content">
                    <h1 className="post-view__title">{data.strapiPost.title}</h1>
                    <time className="post-view__date">{data.strapiPost.created_at}</time>
                </div>
            </BackgroundImage>
            <div className="post-view__container">
                <div className="post-view__column">
                    <p className="post-view__content">
                        <ReactMarkdown source={data.strapiPost.content} />
                    </p>
                    <DiscussionEmbed {...disqusConfig} />
                </div>
                <div className="post-view__sidebar">
                    <About />
                </div>
            </div>
        </div>
    </Layout>
)
}


export default PostTemplate;

export const query = graphql`
    query PostTemplate($slug: String!) {
        strapiPost(slug: {eq: $slug}) {
            title
            content
            created_at(formatString: "DD MMMM YYYY", locale: "pl")
            category {
                name
            }
            image {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`